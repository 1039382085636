import type { ExtensionConfig } from '@goto/shell'

/**
 * The main extension for Contact Center. Other extensions like Agent, Supervisor, Config, Wallboard, etc. are mounted
 * within this extension.
 */
const extensionConfig: ExtensionConfig = {
  id: '@ucc/contact-center',
  url: new URL('/experiences/contact-center/@ucc/contact-center-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    ccFlags: 'cc-supervisor || cc-agent || cc-config.gtc',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
