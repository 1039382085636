import { type NavigationItem } from '@goto/shell-common'
import { EOMObject } from '../eom'
import type { ComponentDefinition } from './component-definition'
import type { ExtensionConfig } from './extension-config'
import type { ExtensionMetadata } from './metadata-definition'
import type { SettingsDefinition } from './settings-definition'
import type { ShellExtension } from './shell-extension'
import type { ShellModule } from './shell-module'

export abstract class BaseShellExtension extends EOMObject implements ShellExtension {
  abstract readonly id: string
  abstract initialize(config: ExtensionConfig): Promise<void>
  abstract registerNamespaces(): Promise<void>
  abstract getString(key: string, ...params: readonly unknown[]): string
  abstract getComponentById(id: string): Promise<ComponentDefinition | null>
  abstract getModules(): readonly ShellModule[]
  abstract getSettings(): readonly SettingsDefinition[]
  abstract getMetadata(): ExtensionMetadata
  abstract getNavigationItems(): readonly NavigationItem[]
}
